import { debounce } from '../components/utils'
import { immerSet } from './useStore'

export const setSelectedOrderId = orderId => immerSet(state => { state.selectedOrderId = orderId })

export const doSearch = (needle, orders) => {
  const ords = orders || []
  if (needle.length === 0) {
    return ords
  }
  if (!ords) {
    return []
  }
  return ords.reduce((acc, order) => {
    return (order.customerName.toLowerCase().search(needle) >= 0 ||
      order.orderNumber.substr(-4, 4).search(needle) >= 0) ?
      [...acc, order] : acc
  }, [])
}

// set ts=CreatedLatest
export const setCreatedLatest = ts => immerSet(state => { state.tsCreatedLatest = ts })

// set value
export const filterUpdate = value => immerSet(state => { state.statusFilter = value })

// search by order number and customer info
const searchUpdate = (search) => immerSet(state => {
  if (state.search !== search) {
    state.search = search.toLowerCase()
  }
})

export const clearSearch = () => searchUpdate('')

export const debouncedSearchUpdate = debounce(searchUpdate)

// FINISHED FILTER
export const finishedFilterUpdate = value => immerSet(state => { state.finishedFilter = value })

const finishedSearchUpdate = (search) => immerSet(state => {
  if (state.finishedSearch !== search) {
    state.finishedSearch = search.toLowerCase()
  }
})

export const clearFinishedSearch = () => finishedSearchUpdate('')

export const debouncedFinishedSearchUpdate = debounce(finishedSearchUpdate)

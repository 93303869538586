import { Badge, Box, Button, chakra, Flex, IconButton, Select, VStack } from '@chakra-ui/react'
import { MdAddShoppingCart, MdRestaurant, MdTimeline, MdShoppingCart, MdHome } from 'react-icons/md'
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker.css'
import './pickuptime.css'
import { forwardRef } from 'react'

export const IconHistory = () => {
  return <IconButton icon={<MdTimeline />} colorScheme='blue' aria-label='history' size='lg' />
}

export const IconList = () => {
  return <IconButton icon={<MdRestaurant />} colorScheme='blue' aria-label='menu' size='lg' />
}

export const IconCart = () => {
  return <IconButton icon={<MdShoppingCart />} color='brand.200' colorScheme='blue' aria-label='cart' size='lg' />
}

export const IconAddToCart = ({ onClick }) => {
  return <IconButton onClick={onClick} isRound icon={<MdAddShoppingCart />} colorScheme='brand' aria-label='add to cart' size='sm' />
}

export const IconHome = () => {
  return <IconButton icon={<MdHome />} colorScheme='green' variant='ghost' aria-label='home' size='sm' />
}

export const FlexBar = ({ children, ...rest }) =>
  <Flex
    bgGradient='linear(to-t, gray.100, white)'
    mb='2' p='3' alignItems='center' boxShadow='md' justifyContent='space-between'
    {...rest}>{children}</Flex>

export const BadgeQty = chakra(Badge, {
  baseStyle: {
    bg: 'brand.600',
    color: 'brand.100'
  }
})

export const BadgeInCart = chakra(Badge, {
  baseStyle: {
    color: 'brand.900',
    fontWeight: '400'
  }
})

export const BadgePickup = chakra(Badge, {
  baseStyle: {
    color: 'blue',
    fontWeight: '600',
    fontSize: '1rem'
  }
})

export const BadgeCreated = chakra(Badge, {
  baseStyle: {
    color: 'gray',
    opacity: '0.5',
    fontWeight: '600',
    fontSize: '1rem'
  }
})

export const BoxCategory = chakra(Box, {
  baseStyle: {
    w: 'full',
    m: '1',
    p: '2',
    bgGradient: 'linear(to-r, black, white)',
    color: 'brand.100',
    fontFamily: 'Montserrat'
  }
})

export const Card = ({ selected, children }) => {
  return (
    <VStack
      w='full'
      alignItems='flex-start'
      spacing='2'
      p={5}
      boxShadow='md'
      borderRadius='md'
      bg={selected ? 'brand.100' : 'gray.50'}
    >
      {children}
    </VStack>
  )
}

export const SimpleButton = ({ onClick, children, ...rest }) => {
  return <Button
    onClick={onClick}
    ml='4' h='8'
    fontSize='0.8rem' fontWeight='500'
    bg='brand.300'
    color='brand.600'
    {...rest}
  >
    {children}
  </Button>
}

export const SimpleSelect = ({ value, onChange, children }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder='Select upsize item...'
      size='sm' py='2'
      bg='brand.50'>
      {children}
    </Select>
  )
}

export const StyledDatepicker = props => (
  <DatePicker w='full' h='8' fontSize='md' {...props} />
)

export const PickerButton = forwardRef(({ value, onClick }, ref) => (
  <Button variant='outline' onClick={onClick} ref={ref}>
    {value}
  </Button>
))

import React from 'react'
import useResponsiveRoute from './routes/useResponsiveRoute'
import useAuth from './services/useAuth'
import { Box, VStack } from '@chakra-ui/react'
import Appbar from './components/Appbar'

const style = {
  width:'100%',
  display: 'flex',
  justifyContent: 'space-between'
}

function App() {
  const { auth } = useAuth()
  const element = useResponsiveRoute(auth && auth.accessToken)

  return (
    <VStack>
      {auth.accessToken && <Appbar />}
      <Box style={style}>{element}</Box>
    </VStack>
  )
}

export default App

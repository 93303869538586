import { Badge, Checkbox, CheckboxGroup, HStack, Input, InputGroup, InputLeftElement, InputRightElement, Spacer, Stack }
  from '@chakra-ui/react';
import { useRef } from 'react';
import { MdClear, MdSearch } from 'react-icons/md';
import { clearFinishedSearch, debouncedFinishedSearchUpdate, finishedFilterUpdate } from '../states/storeActions';
import { useStore } from '../states/useStore';
import useAuth from '../services/useAuth'


const needSearchBox = false; // HOANG 2023 07 19: search box is optional
const Search = ({ search, onKeyUp, onClear }) => {
  const ref = useRef();
  const clearHandle = () => {
    ref.current.value = '';
    onClear();
  };
  return (
    <InputGroup>
      <InputLeftElement pointerEvents='none' children={<MdSearch />} size='sm' pb='1' />
      <Input onKeyUp={onKeyUp} ref={ref} placeholder='Search...' defaultValue={search} size='sm' />
      {search.length > 0 && <InputRightElement onClick={clearHandle} children={<MdClear />} size='sm' pb='2' />}
    </InputGroup>
  );
};

const FilterBoxFinished = ({ total, qtyTotal, ordersNo }) => {
  const { auth } = useAuth()
  const search = useStore(state => state.finishedSearch);
  const statusFilter = useStore(state => state.finishedFilter);

  const handler = (value) => {
    console.log('FilterBox handler', value);
    finishedFilterUpdate(value);
  };

  return (
    <HStack justify='space-between'>
      <CheckboxGroup defaultValue={statusFilter} onChange={handler}>
        <Stack spacing={[1, 5]} direction='row' align='center' justify='flex-start'>
          <Checkbox value={'10'}>P</Checkbox>
          <Checkbox value={'1'}>C</Checkbox>
          <Checkbox value={'0'}>I</Checkbox>
        </Stack>
      </CheckboxGroup>
      <Spacer />
      {needSearchBox && <Search
        search={search}
        onKeyUp={e => debouncedFinishedSearchUpdate(e.target.value)}
        onClear={clearFinishedSearch}
      />}
      <Badge>{`${total && total.qty} items | ${ordersNo} orders`}</Badge>
      {auth.role === 'MANAGER' && <Badge color='red'>{`$${(total && total.amt / 100).toFixed(2)}`}</Badge>}
    </HStack>
  );
};

export default FilterBoxFinished;
import { format } from 'date-fns'

// Helpers
const itemNo = cart => {
  return cart.reduce((acc, item) => {
    return acc + item.qty
  }, 0)
}

const selectedUpsizeNo = cart => {
  return cart.reduce((acc, item) => {
    return item.selectedExtra ? acc + item.qty : acc
  }, 0)
}

export const qtyText = cart => {
  if (!cart || cart.length === 0) {
    return ''
  }
  const items = itemNo(cart)
  const itemText = items === 1 ? `${items} item` : `${items} items`

  const upsizes = selectedUpsizeNo(cart)
  const upsizeText = upsizes === 0 ? '' : upsizes === 1 ? `${upsizes} upsize` : `${upsizes} upsizes`

  return `${itemText}  ${upsizeText}`
}

export const calTotal = cart => {
  const cartTotal = cart.reduce((acc, i) => {
    const amount = i.unitAmount + (i.selectedExtra ? i.selectedExtra.unitAmount : 0)
    return acc + amount * i.qty
  }, 0)
  return (cartTotal / 100).toFixed(2)
}

export const timestampToLocalDateCompact = ts => {
  return ts ? format(ts, 'ddMMyyyy hh:mm') : ''
}

export const timestampToLocalDate = ts => {
  // return format(ts, 'mm/dd/yyyy hh:mm')
  return ts ? format(ts, 'dd-MM-yyyy hh:mm') : ''
}

export const debounce = (func, wait = 200, immediate = false) => {
  let timeout
  return function (...args) {
    var later = function () {
      timeout = null
      if (!immediate) func(...args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func(...args)
  }
}


import { format } from 'date-fns';

import { Text, Container, Divider, HStack, Stack, Box } from '@chakra-ui/react';
import useAuth from '../services/useAuth';
import { usePendingOrdersByLocId } from '../states/useRemoteStates';
import Left from './Left';
import { qtyText } from './utils';
import OrderHeader from './OrderHeader';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../states/useStore';
import { doSearch, setSelectedOrderId } from '../states/storeActions';
import { BadgeCreated, BadgePickup } from '../common';

const OrderDetails = ({ order }) => {
  const navigate = useNavigate();
  const { id, customerName, customerPhone, cart, tsPickup, tsCreated } = order;

  const selectedOrderId = useStore(state => state.selectedOrderId);

  const handler = orderId => e => {
    e.preventDefault();
    if (orderId) {
      setSelectedOrderId(orderId);
      navigate(`order/${orderId}`);
    }
  };

  return (
    <Container
      onClick={handler(id)}
      w='full'
      bg={selectedOrderId && selectedOrderId === id ? 'gray.300' : 'none'}
    >
      <Box>
        <OrderHeader order={order} />
        <Stack mt='4' direction='row' align='center' justify='space-between'>
          <Text color={customerPhone && customerPhone.length > 0 ? 'blue' : 'red'}>
            {customerName}
          </Text>
          <div>{qtyText(cart)}</div>
          <HStack spacing={4}>
            <Text>{tsPickup && format(tsPickup, 'dd/MM')}</Text>
            <BadgeCreated>{tsCreated && format(tsCreated, 'HH:mm')}</BadgeCreated>
            <BadgePickup>{tsPickup && format(tsPickup, 'HH:mm')}</BadgePickup>
          </HStack>
        </Stack>
        <Divider borderColor='gray.300' my='4' />
      </Box>
    </Container>
  );
};

const Orders = () => {
  return (
    <Left>
      <List />
    </Left>
  );
};

export default Orders;

const sort = (a, b) => {
  if (a.tsPickup > b.tsPickup) {
    return true;
  }
  if (a.tsPickup === b.tsPickup) {
    return a.tsCreated > b.tsCreated;
  }
  return false;
};

const List = () => {
  const search = useStore(state => state.search);
  const filter = useStore(state => state.statusFilter);

  const { auth } = useAuth();

  const query = usePendingOrdersByLocId(auth?.location);

  const orders = query.data?.data;

  return (
    <Container overflowY='scroll' w='full' minH='90vh' p='6' borderWidth='1px' borderColor='black' borderRadius='lg'>
      {doSearch(search, orders && orders.filter(order => filter.includes(`${order.status}`)))
        .sort(sort)
        .map(order =>
          <OrderDetails order={order} key={order.id} />
        )}
    </Container>
  );
}


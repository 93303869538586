// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export const debounce = (func, wait = 200, immediate = false) => {
  let timeout;
  return function (...args) {
    var later = function () {
      timeout = null;
      if (!immediate) func(...args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func(...args);
  };
};

export const random3digits = () => Math.floor(Math.random() * (999 - 100 + 1) + 100);

export const random4digits = () => Math.floor(Math.random() * (9999 - 1000 + 1) + 1000);

// to flatten selected to text
export const optionArrayToText = optionArr => {
  const text = optionArr
    .filter(opt => opt.checked)
    .map(opt => opt.label)
    .join(', ');
  return text.length === 0 ? 'None' : text;
};

export const calQtyAndAmountTotal = orders => {
  const cartQtyAmt = cart => {
    return cart.reduce((acc, item) => {
      return {
        qty: acc.qty + item.qty,
        amt: acc.amt + item.qty * item.unitAmount
      };
    }, { qty: 0, amt: 0 });
  };

  return orders.reduce((acc, order) => {
    const { qty, amt } = cartQtyAmt(order.cart);
    return {
      qty: acc.qty + qty,
      amt: acc.amt + amt
    };
  }, { qty: 0, amt: 0 });
};

export const calQtyTotal = orders => {
  const calQty = cart => {
    return cart.reduce((acc, item) => acc + item.qty, 0);
  };

  return orders.reduce((acc, order) => acc + calQty(order.cart), 0);
};

export const findCreatedLatest = orders => {
  if (!orders || orders.length === 0) {
    return 0;
  }
  return orders.reduce((acc, order) => {
    return order.tsCreated > acc ? order.tsCreated : acc;
  }, 0);
}


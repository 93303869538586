import { Button } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { loginPath } from '../configs/configs'
import useAuth from '../services/useAuth'

const LogoutButton = () => {
  const navigate = useNavigate()
  const { auth, setAuth } = useAuth()

  const logout = () => {
    setAuth({})
    setTimeout(() => {
      navigate(loginPath)
    }, 0)
  }

  return auth && auth.accessToken && <Button onClick={logout}>Logout</Button>
}

export default LogoutButton
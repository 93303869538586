import { useFinishedOrdersByLocId } from '../states/useRemoteStates'
import useAuth from '../services/useAuth'
import { useStore } from '../states/useStore'
import FilterBoxFinished from './FilterBoxFinished'
import { calQtyAndAmountTotal } from '../common/utils'

const itemList = orders => {
  const objList = orders.reduce((acc, order) => {
    order.cart.forEach(item => {
      const { key, name, qty } = item
      if (!acc || !acc[key]) {
        acc[key] = { key, name, qty }
      } else {
        acc[key].qty = acc[key].qty + qty
      }
    })
    return acc
  }, {})
  return Object.values(objList).sort((a, b) => a.name <= b.name ? -1 : 1)
}

const FinishedItems = ({ ts }) => {
  const { auth } = useAuth()

  // const search = useStore(state => state.finishedSearch)
  const filter = useStore(state => state.finishedFilter)
  const query = useFinishedOrdersByLocId(auth?.location, ts)

  const orders = query.data?.data || []
  const filterOrders = orders.filter(order => filter.includes(`${order.status}`))
  const items = itemList(filterOrders)

  return (
    <>
      {filterOrders && <FilterBoxFinished total={calQtyAndAmountTotal(filterOrders)} ordersNo={filterOrders.length} />}
      {items && items.map(item => <li key={item.key}>{`${item.name} | ${item.qty}`}</li>)}
    </>
  )
}

export default FinishedItems
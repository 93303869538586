import { Badge, Box, Flex, Spacer, Text, VStack } from '@chakra-ui/react'

const optionArrayToText = optionArr => {
  const text = optionArr
    .filter(opt => opt.checked)
    .map(opt => opt.enable? `<span style="color: blue; font-weight: bold">${opt.label.toUpperCase()}</span>`:  opt.label)
    .join(', ')
  return text.length === 0 ? 'None' : text
}

const toText = selected =>
  Object.keys(selected).reduce(
    (acc, type) => acc + `<div><span>${type}:</span>${optionArrayToText(selected[type])}</div>`,
    ''
  )

const SelectedToText = ({ selected }) => {
  return (
    <Box
      sx={{
        '> div > span': {
          mr: '1',
          color: 'red.500',
          fontSize: 'sm',
          fontWeight: '500'
        }
      }}
      isTruncated
      dangerouslySetInnerHTML={{ __html: toText(selected) }} />
  )
}

const ItemOptions = ({ item }) => {
  return (
    <Box my='2'>
      <SelectedToText selected={item.selectedOptions} />
    </Box>
  )
}

const ItemExtras = ({ item }) => {
  // const noExtra = { key: 'NONE', name: 'No extra...' }
  const { name } = item.selectedExtra
  const qty = item.qty // qty of extra === qty of item
  return (
    <Flex my='3' color='blue'>
      <Text>{name}</Text>
      <Spacer />
      <Text>{qty}</Text>
    </Flex>
  )
}

const Item = ({ item }) => {
  const isAnyOptions = item => item.selectedOptions
  const isAnyExtra = item => item.selectedExtra
  return (
    <Box
      w='full'
      p='3'
      border='1px'
      borderRadius='md'
      id={item.itemId}
    >
      <Flex>
        <Text>{item.name}</Text>
        <Spacer />
        <Badge>{item.qty}</Badge>
      </Flex>
      {isAnyExtra(item) && <ItemExtras item={item} />}
      {isAnyOptions(item) && <ItemOptions item={item} />}
    </Box>
  )
}
const CartInfo = ({ cart }) => {
  return (
    <VStack w='full' mt='4' spacing='4'>
      {cart && cart.map((item, idx) =>
        <Item key={idx} item={item} />)}
    </VStack>

  )
}

export default CartInfo
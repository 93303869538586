import { useEffect } from "react"
import useAuth from "./useAuth"
import axios from 'axios'
import { useRefreshToken } from '../states/useRemoteStates'

const BASE_URL = process.env.REACT_APP_API_URL

const AxiosAuth = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json', 'crossDomain': true },
    withCredentials: true,
    crossDomain: true
})

// Intercepts request and response
const useAxiosAuth = () => {
    const refresh = useRefreshToken()
    const { auth } = useAuth()

    useEffect(() => {
        const requestIntercept = AxiosAuth.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.accessToken}`
                }
                return config
            },
            (error) => Promise.reject(error)
        )

        const responseIntercept = AxiosAuth.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config
                if (error?.response?.status === 403 && !prevRequest?.sent) {
                    prevRequest.sent = true
                    const data = await refresh.mutate(auth.refreshToken)
                    prevRequest.headers['Authorization'] = `Bearer ${data.accessToken}`
                    return AxiosAuth(prevRequest)
                }
                return Promise.reject(error)
            }
        )

        return () => {
            AxiosAuth.interceptors.request.eject(requestIntercept)
            AxiosAuth.interceptors.response.eject(responseIntercept)
        }
    }, [auth, refresh])

    return AxiosAuth
}

export default useAxiosAuth
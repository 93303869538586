import { Center, Image } from '@chakra-ui/react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader

const images = {
  '1-fresh': require('../assets/1-fresh.jpg'),
  '2-banhmi': require('../assets/2-banhmi.jpg'),
  '3-sprroll': require('../assets/3-sprroll.jpg'),
  '4-salad': require('../assets/4-salad.jpg'),
  '5-rice': require('../assets/5-rice.jpg'),
  '6-coffee': require('../assets/6-coffee.jpg')
}

const imageNames = Object.keys(images)

const ShowPage = () => {
  return (
    <Center w='full' p='2'>
      <Carousel
        infiniteLoop
        autoPlay
        interval={10000}
        transitionTime={3000}
        animationHandler={'fade'}
      >
        {imageNames.map(imgName => {
          return <Image src={images[imgName]} key={imgName} />
        })}
      </Carousel>
    </Center>
  )
}

export default ShowPage
import { Container } from '@chakra-ui/react'
import { usePendingOrdersByLocId } from '../states/useRemoteStates'

const PrivatePage = () => {
  const query = usePendingOrdersByLocId('PR01')

  const orders = query.data?.data || []
  const sort = (a, b) => a.tsCreated > b.tsCreated
  return (
    <Container minH='90vh' p='6' bg='tomato'>
      <p>A PRIVATE page</p>
      <ul>
        {orders.sort(sort).map((order, idx) => {
          return (
            <div key={idx} style={{ margin: '24px'}}>
              <div>Status: {order.status}</div>
              <div>tsCreated: {order.tsCreated}</div>
              <div>{order.id}</div>
              <div>{order.locId}</div>
              <div>{order.customerName}</div>
              <div>{order.customerEmail}</div>
              <hr style={{ marginTop: '24px'}}/>
            </div>
          )
        })}
      </ul>
    </Container>
  )
}

export default PrivatePage
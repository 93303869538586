import { Badge, Box, HStack, Stack, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { nextStatus, statusColors, statusNames } from '../configs/order-status-machine'
import useAuth from '../services/useAuth'
import { useUpdateStatus } from '../states/useRemoteStates'

const OrderHeader = ({ order }) => {
  const statusUpdate = useUpdateStatus()
  const { auth } = useAuth()

  const { orderNumber, status, id, locId, staffName } = order
  const log = { staffId: auth.email, staffName: auth.name, id, locId, status, nextStatus: nextStatus[status] }

  const nav = useNavigate()

  const handler = () => {
    if (!statusNames[nextStatus[status]]) {
      return
    }

    statusUpdate.mutate({ ...log, tsLocation: Date.now() }, {
      onSuccess: () => {
        setTimeout(() => {
          nav(`order/${id}`)
        }, 0)
      },
      onError: console.log // to be send error message
    })
  }

  return (
    <Stack pt='8px' direction='row' align='center' justify='space-between'>
      <Text>#{orderNumber.substr(orderNumber.length - 4, 4)}</Text>
      <HStack>
        <Badge fontSize={'md'} color={`${statusColors[status]}`}>{statusNames[status]}</Badge>
        <Text>{staffName ? `/${staffName}` : ''}</Text>
      </HStack>
      <Box
        borderRadius='3px'
        px='8px'
        py='3px'
        bg='gray.700'
        color='white'
        fontSize={'sm'}
        onClick={handler}
      >
        Next: {statusNames[nextStatus[status]] || 'N/A'}
      </Box>
    </Stack>
  )
}

export default OrderHeader
import { useMediaQuery } from 'react-responsive'
import { matchRoutes, Navigate, useLocation } from 'react-router-dom'

import Login from '../components/Login'
import Signup from '../components/Signup'
import ShowPage from '../components/ShowPage'
import PrivatePage from '../components/PrivatePage'

import NotFound from '../components/NotFound'
import { loginPath } from '../configs/configs'
import Orders from '../components/Orders'
import Order from '../components/Order'

const routes = [
  {
    path: loginPath,
    element: Login,
    responsive: [Login],
    public: true
  },
  {
    path: '/orders',
    element: Orders,
    responsive: [Orders, Order]
  },
  {
    path: '/order/:orderId',
    element: Order,
    responsive: [Orders, Order]
  },
  {
    path: '/signup',
    element: Signup,
    responsive: [Signup],
    public: true
  },
  {
    path: '/private',
    element: PrivatePage,
    responsive: [PrivatePage]
  },
  {
    path: '/show/2',
    element: ShowPage,
    responsive: [ShowPage],
    public: true
  },
  {
    path: "*",
    element: NotFound,
    public: true
  }
]

const useResponsiveRoute = (isAuthed) => {
  const isSmall = useMediaQuery({ maxWidth: 736 - 1 })
  const location = useLocation()
  const matches = matchRoutes(routes, location)

  if (!matches || matches.length === 0) {
    return null
  }

  const pathname = matches[0].pathname
  const route = matches[0].route

  // if not authenticated, show Sign in page
  if (!route.public && !isAuthed && pathname !== loginPath) {
    return <Navigate to={loginPath} state={{ from: location }} replace />
  }


  // small layout
  if (isSmall) {
    const TheComponent = route.element
    return <TheComponent />
  }

  // big layout
  return (
    <>
      {route.responsive.map((c, idx) => {
        const TheComponent = c
        return <TheComponent key={idx} />
      })}
    </>
  )
}

export default useResponsiveRoute
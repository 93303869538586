import { Text, Container, HStack, Stack, Center } from '@chakra-ui/react'
import { useMatch } from 'react-router-dom'
import { usePendingOrdersByLocId } from '../states/useRemoteStates'
import useAuth from '../services/useAuth'
import { qtyText } from './utils'
import OrderHeader from './OrderHeader'
import { format } from 'date-fns'
import CartInfo from './CartInfo'
import { BadgePickup } from '../common'

const Order = () => {
  // hooks should run out of conditional branches
  const { auth } = useAuth()
  const query = usePendingOrdersByLocId(auth?.location)
  const match = useMatch('order/:orderId')

  // No order to show
  const orderId = match?.params.orderId
  if (!orderId) return <Center w='full'>No order selected</Center>

  const orders = query.data?.data || []
  const order = orders.find(order => order.id === orderId)

  // No order to show
  if (!order) return <Center w='full'>No order selected</Center>

  const {
    tsPickup,
    customerName,
    customerPhone,
    customerEmail,
    cart
  } = order

  const handler = (customerName, customerPhone, customerEmail) => e => {
    const msg = customerName && customerPhone ?
      `${customerName} / ${customerPhone} / ${customerEmail}`
      :
      customerName
    alert(msg)
  }

  return (
    <Container>
      <OrderHeader order={order} />
      <Stack mt='4' direction='row' align='center' justify='space-between'>
        <Text
          onClick={handler(customerName, customerPhone, customerEmail)}
          color={customerPhone ? 'blue' : 'red'}
        >
          {customerName}
        </Text>
        <Text>{qtyText(cart)}</Text>
        <HStack spacing={4}>
          <Text>{format(tsPickup, 'dd/MM')}</Text>
          <BadgePickup>{format(tsPickup, 'HH:mm')}</BadgePickup>
        </HStack>
      </Stack>
      <CartInfo cart={cart} />
    </Container>
  )
}

export default Order

import { format } from 'date-fns'

import { Text, Badge, Container, HStack, Stack, AccordionItem, AccordionButton, AccordionPanel, Accordion } from '@chakra-ui/react'
import useAuth from '../services/useAuth'
import { useFinishedOrdersByLocId } from '../states/useRemoteStates'
import { qtyText } from './utils'
import { useStore } from '../states/useStore'
import { doSearch } from '../states/storeActions'
import FilterBoxFinished from './FilterBoxFinished'
import { statusColors, statusNames } from '../configs/order-status-machine'
import CartInfo from './CartInfo'
import { calQtyAndAmountTotal } from '../common/utils'

const OrderHeader = ({ order }) => {
  const { orderNumber, status, staffName } = order
  return (
    <Stack direction='row' align='center' justify='space-between'>
      <Text>#{orderNumber.substr(orderNumber.length - 4, 4)}</Text>
      <HStack>
        <Badge fontSize={'md'} color={`${statusColors[status]}`}>{statusNames[status]}</Badge>
        <Text>{staffName ? `/${staffName}` : ''}</Text>
      </HStack>
    </Stack>
  )

}
const OrderBrief = ({ order }) => {
  const { customerName, customerPhone, customerEmail, cart, tsPickup } = order
  return (
    <Container w='full'>
      <OrderHeader order={order} />
      <Stack mt='4' direction='row' align='center' justify='space-between'>
        <Text
          color={customerPhone && customerPhone.length > 0 ? 'blue' : 'red'}
          onClick={e => alert(`${customerName}/${customerPhone || ''}/${customerEmail || ''}`)}
        >
          {customerName}
        </Text>
        <Text>{qtyText(cart)}</Text>
        <HStack spacing={4}>
          <Text>{format(tsPickup, 'dd/MM')}</Text>
          <Badge>{format(tsPickup, 'HH:mm')}</Badge>
        </HStack>
      </Stack>
    </Container>
  )
}

const OrderInfo = ({ order }) => {
  const { cart } = order
  return (
    <Container>
      <CartInfo cart={cart} />
    </Container>
  )
}

const FinishedOrders = ({ ts }) => {
  // params 'ts':  /:ts
  const { auth } = useAuth()

  const search = useStore(state => state.finishedSearch)
  const filter = useStore(state => state.finishedFilter)

  const query = useFinishedOrdersByLocId(auth?.location, ts)

  const orders = query.data?.data || []
  const filterOrders = orders.filter(order => filter.includes(`${order.status}`))

  // const {qty, amt} = calQtyAndAmountTotal(filterOrders)
  return (
    <>
      {filterOrders && <FilterBoxFinished total={calQtyAndAmountTotal(filterOrders)} ordersNo={filterOrders.length} />}
      <List ts={ts} search={search} orders={filterOrders} />
    </>
  )
}

export default FinishedOrders

const sort = (a, b) => a.tsPickup > b.tsPickup

const List = ({ ts, search, orders }) => {
  return (
    <Container w='full' overflowY='scroll' minH='90vh' py='6' borderWidth='1px' borderColor='black' borderRadius='lg'>
      <Accordion allowToggle>
        {
          doSearch(search, orders)
            .sort(sort)
            .map((order, idx) =>
              <AccordionItem key={idx}>
                <AccordionButton>
                  <OrderBrief order={order} />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <OrderInfo order={order} />
                </AccordionPanel>
              </AccordionItem>
            )
        }
      </Accordion>
    </Container>
  )
}


import { Checkbox, CheckboxGroup, HStack, Input, InputGroup, InputLeftElement, InputRightElement, Spacer, Stack }
  from '@chakra-ui/react';
import { useRef } from 'react';
import { MdClear, MdSearch } from 'react-icons/md';
import { clearSearch, debouncedSearchUpdate, filterUpdate } from '../states/storeActions';
import { useStore } from '../states/useStore';

const needSearchBox = false; // HOANG 2023 07 19: search box is optional

const Search = ({ search, onKeyUp, onClear }) => {
  const ref = useRef();
  const clearHandle = () => {
    ref.current.value = '';
    onClear();
  };
  return (
    <InputGroup>
      <InputLeftElement pointerEvents='none' children={<MdSearch />} size='sm' pb='1' />
      <Input onKeyUp={onKeyUp} ref={ref} placeholder='Search...' defaultValue={search} size='sm' />
      {search.length > 0 && <InputRightElement onClick={clearHandle} children={<MdClear />} size='sm' pb='2' />}
    </InputGroup>
  );
};

const FilterBox = () => {
  const search = useStore(state => state.search);
  const statusFilter = useStore(state => state.statusFilter);

  const handler = (value) => {
    console.log('FilterBox handler', value);
    filterUpdate(value);
  };

  return (
    <HStack justify='space-between'>
      <CheckboxGroup defaultValue={statusFilter} onChange={handler}>
        <Stack spacing={[1, 5]} direction='row' align='center' justify='flex-start'>
          <Checkbox value={'40'}>N</Checkbox>
          <Checkbox value={'30'}>M</Checkbox>
          <Checkbox value={'20'}>R</Checkbox>
        </Stack>
      </CheckboxGroup>
      <Spacer />
      {needSearchBox && <Search
        search={search}
        onKeyUp={e => debouncedSearchUpdate(e.target.value)}
        onClear={clearSearch}
      />}
    </HStack>
  );
};

export default FilterBox;
import { useMutation, useQuery, useQueryClient } from 'react-query'
import jwtDecode from 'jwt-decode'

import Axios from '../services/Axios'
import useAuth from '../services/useAuth'
import useAxiosAuth from '../services/useAxiosAuth'
import sndNew from '../assets/snd-new.mp3'
import { useStore } from './useStore'
import { findCreatedLatest } from '../common/utils'
import { setCreatedLatest } from './storeActions'

import { Player } from 'tone'

const player = new Player(sndNew).toDestination()

const BASE_URL = process.env.REACT_APP_API_URL

const urlLogin = '/auth/login'
const urlSignup = '/auth/signup'
const urlRefreshToken = '/auth/refresh-token'
const urlPendingOrdersByLocId = locId => BASE_URL + '/order/pending-orders/' + locId
const urlFinishedOrdersByLocId = (locId, ts) => `${BASE_URL}/order/finished-orders/${locId}/${ts}`
const urlStatusUpdate = '/order/status-update'
/* QUERY */
// keys
export const qKeys = {
  order: 'order',
  pendingOrdersByLocId: locId => [...qKeys.order, 'pending-orders', locId],
  finishedOrdersByLocId: (locId, ts) => [...qKeys.order, 'finished-orders', locId, ts]
}

export const usePendingOrdersByLocId = locId => {
  const axiosAuth = useAxiosAuth()
  const tsCreatedLatest = useStore(state => state.tsCreatedLatest)

  return useQuery(
    qKeys.pendingOrdersByLocId(locId),
    () => axiosAuth.get(urlPendingOrdersByLocId(locId)),
    {
      refetchInterval: 20000, // 20s
      initialData: () => [],
      onSuccess: ({ data }) => {
        const tsLatestNew = findCreatedLatest(data)
        if (tsLatestNew > tsCreatedLatest) {
          console.log('tsCreatedLatest tsLatestNew DIFF', tsCreatedLatest, tsLatestNew, tsLatestNew - tsCreatedLatest)
          console.log('should play sound Co order!')
          // const snd = new Audio(sndNew)
          // snd.play()
          player.start()

          setCreatedLatest(tsLatestNew)
        }
      },
      onError: console.log
    })
}

export const useFinishedOrdersByLocId = (locId, ts) => {
  const axiosAuth = useAxiosAuth()
  return useQuery(
    qKeys.finishedOrdersByLocId(locId, ts),
    () => axiosAuth.get(urlFinishedOrdersByLocId(locId, ts)),
    {
      // staleTime: Infinity,
      // refetchInterval: 60000, // 20s
      initialData: () => [],
      onError: console.log
    })
}

/* MUTATIONS */
/*
  creds = {email, password, location}
*/
export const useSignup = () => {
  return useMutation(creds => Axios.post(urlSignup, creds))
}

/*
  creds = {email, password, location}
*/
export const useLogin = () => {
  const { setAuth } = useAuth()
  const fn = creds => Axios.post(urlLogin, creds)
  const onSuccess = ({ data }) => {
    const { accessToken, refreshToken } = data
    const { exp, name, email, location, role } = jwtDecode(accessToken)
    console.log('useLogin exp, name, email, location, role', exp, name, email, location, role)
    setAuth({ accessToken, refreshToken, exp, name, email, location, role })
  }

  return useMutation(fn, { onSuccess, onError: console.error }
  )
}

/*
  refreshToken = current valid refreshToken
*/
export const useRefreshToken = () => {
  const { setAuth } = useAuth()
  const fn = refreshToken => Axios.post(urlRefreshToken, { refreshToken })
  const onSuccess = ({ data }) => {
    const { accessToken, refreshToken } = data
    const { exp, name, email, location, role } = jwtDecode(accessToken)
    setAuth({ accessToken, refreshToken, exp, name, email, location, role })
  }
  return useMutation(fn, { onSuccess, onError: console.error })
}

export const useUpdateStatus = () => {
  const axiosAuth = useAxiosAuth()
  const queryClient = useQueryClient()
  const fn = statusUpdateLog => axiosAuth.post(urlStatusUpdate, { log: statusUpdateLog })
  const onSuccess = ({ data }) => {
    const { locId } = data
    queryClient.invalidateQueries(qKeys.pendingOrdersByLocId(locId))
    return data
  }
  return useMutation(fn, { onSuccess, onError: console.error })
}
import React from "react"
import { Text, HStack, Flex, Center, Image, useDisclosure } from '@chakra-ui/react'
import useAuth from '../services/useAuth'
import lighthouse from '../assets/portroll.svg'
import FilterBox from './FilterBox'
import ModalReports from './ModalReports'

import sndHey from '../assets/hey.mp3'
import * as Tone from 'tone'

const AppBar = () => {
  const { auth } = useAuth()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const testSoundHandler = e => {
    console.log('should play HEY')
    // snd.play()
    Tone.start()
      .then(() => {
        console.log('audio is ready')
        const player = new Tone.Player(sndHey).toDestination()
        Tone.loaded().then(() => {
          player.start()
        })
      })
  }

  return (
    <Flex padding='3' h='48px' w='full' align="center" justify="space-between"
      bg='gray.50' boxShadow='md'>
      <Center onClick={testSoundHandler}><Image p='2' h='32px' src={lighthouse} /></Center>
      <FilterBox />
      <HStack onClick={onOpen}>
        <Text>😋 </Text>
        <Text>{auth.name}</Text>
        {auth.role === 'MANAGER' && <Text>{auth.role}</Text>}
      </HStack>
      <ModalReports isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}

export default AppBar

import produce from 'immer'
import create from 'zustand'
import { persist, devtools } from "zustand/middleware"

import { statuses } from '../configs/order-status-machine'

const fn = (set, get) => ({
  statusFilter: [`${statuses.NEW}`, `${statuses.MAKING}`, `${statuses.READY}`],
  search: '',
  tsCreatedLatest: 0,
  finishedFilter: [`${statuses.PICKED}`, `${statuses.CANCELLED}`, `${statuses.INTENT}`],
  finishedSearch: '',
  selectedOrderId: null
})

// const useStore = create(persist(fn, options))
export const useStore = create(devtools(persist(fn, { name: 'store' })))

// convenient set/get functions
export const immerSet = f => useStore.setState(produce(f))
export const set = useStore.setState
export const get = useStore.getState


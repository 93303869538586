import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'

import { useSignup } from '../states/useRemoteStates'
import { defaultRole } from '../configs/configs'

export default function SignupCard() {
  const signup = useSignup()

  const [showPassword, setShowPassword] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [location, setLocation] = useState('')

  const nameHandler = e => setName(e.target.value)
  const emailHandler = e => setEmail(e.target.value)
  const passwordHandler = e => setPassword(e.target.value)
  const locationHandler = e => setLocation(e.target.value)

  const [error, setError] = useState()

  const handler = (name, email, password, location) => () => {
    signup.mutate({ name, email, password, location, role: defaultRole }, {
      onSuccess: ({ data }) => {
        setError(null)
      },
      onError: err => {
        console.log(err)
        setError(new Error(err.message))
      }
    })

  }

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} bg='gray.50'>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'2xl'} textAlign={'center'}>
            Sign up
          </Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            to enjoy all of our cool features ✌️
          </Text>
        </Stack>
        <Box rounded={'lg'} bg='gray.50' boxShadow={'lg'} p={8}>
          <Stack spacing={4}>
            <FormControl id="name" isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                onChange={nameHandler}
                type="name" />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                onChange={emailHandler}
                type="email" />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input onChange={passwordHandler} type={showPassword ? 'text' : 'password'} />
                <InputRightElement h={'full'}>
                  <Button variant={'ghost'} onClick={() => setShowPassword((showPassword) => !showPassword)}>
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl id="location" isRequired>
              <FormLabel>Location</FormLabel>
              <Input onChange={locationHandler} type="text" />
            </FormControl>
            <Stack spacing={10} pt={2}>
              <Button loadingText="Submitting" size="lg" bg={'blue.400'} color={'white'}
                _hover={{ bg: 'blue.500', }}
                onClick={handler(name, email, password, location)}
              >
                Sign up
              </Button>
            </Stack>
            {
              error &&
              <Text align={'center'}>{error.message}</Text>
            }
            <Stack pt={6}>
              <Text align={'center'}>
                Already a user? <Link to='/' color={'blue.400'}>Login</Link>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  )
}
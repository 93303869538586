import { Text, HStack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Button, Spacer, Container, Box } from '@chakra-ui/react'

import LogoutButton from './LogoutButton'
import { IconHistory, PickerButton, StyledDatepicker } from '../common'
import { getTime, startOfDay } from 'date-fns'
import useAuth from '../services/useAuth'
import { useState } from 'react'
import FinishedOrders from './FinishedOrders'
import { useNavigate } from 'react-router-dom'
import FinishedItems from './FinishedItems'

const ModalReports = ({ isOpen, onClose }) => {
  const nav = useNavigate()
  const { auth } = useAuth()
  const [date, setDate] = useState(Date.now())
  const [show, setShow] = useState(false)

  const handler = () => {
    nav('/orders')
    onClose()
  }

  return <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent h='95%' overflowY='scroll'>
      <ModalHeader>
        <HStack>
          <Text>😋  {auth.name}</Text>
          <Spacer />
          <Button variant='link' onClick={handler}>Orders</Button>
          <LogoutButton />
          <Spacer />
          <ModalCloseButton />
        </HStack>
      </ModalHeader>
      <ModalBody>
        <HStack>
          <StyledDatepicker
            w='9rem'
            selected={date}
            onChange={setDate}
            dateFormat='iiii, d/MM'
            customInput={<PickerButton />}
          />
          <Box onClick={() => setShow(!show)}><IconHistory /></Box>
        </HStack>
        <Container w='full' pt='2' m='0'>
        {show && <FinishedOrders ts={getTime(startOfDay(date))} />}
        {!show && <FinishedItems ts={getTime(startOfDay(date))} />}
        </Container>
      </ModalBody>
    </ModalContent>
  </Modal>
}

export default ModalReports
import { Container } from '@chakra-ui/react'

const Left = ({ children }) => {
  return (
    <Container w='500px' px='6'>
      {children}
    </Container>
  )
}

export default Left
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  Center,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { landingPath } from '../configs/configs'

import { useLogin } from '../states/useRemoteStates'

export default function LoginCard() {
  const navigate = useNavigate()
  const loc = useLocation()
  const from = loc.state?.from?.pathname || landingPath

  const login = useLogin()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [location, setLocation] = useState('')

  const emailHandler = e => setEmail(e.target.value)
  const passwordHandler = e => setPassword(e.target.value)
  const locationHandler = e => setLocation(e.target.value.toUpperCase())

  const [error, setError] = useState()

  const handler = (email, password, location) => () => {
    login.mutate({ email, password, location }, {
      onSuccess: ({ data }) => {
        setError(null)
        setTimeout(() => { navigate(from, { replace: true }) }, 0)
      },
      onError: err => {
        setError(new Error('Please enter correct info'))
        console.log(err.message)
      }
    })
  }

  return (
    <Center w='full'>
      <Stack spacing='8' py='12'>
        <Stack align='center'>
          <Heading fontSize='2xl'>Log in</Heading>
          <Text>to start your day with 😄 😍 💗</Text>
        </Stack>
        <Box w='25rem' rounded='lg' boxShadow='lg' p='8'>
          <Stack spacing='4'>
            <FormControl id='email'>
              <FormLabel>Email</FormLabel>
              <Input type='email' onChange={emailHandler} value={email} />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <PasswordInput passwordHandler={passwordHandler} />
            </FormControl>
            <FormControl id="location">
              <FormLabel>Location</FormLabel>
              <Input type="text" onChange={locationHandler} value={location} />
            </FormControl>

            <Button onClick={handler(email, password, location)}>Sign in</Button>
            {error && <Text color='red'>{error.message}</Text>}
          </Stack>
        </Box>
      </Stack>
    </Center>
  )
}


const PasswordInput = ({ passwordHandler }) => {
  const [show, setShow] = useState(false)

  return (
    <InputGroup>
      <Input type={show ? 'text' : 'password'} onChange={passwordHandler} />
      <InputRightElement width='4.5rem'>
        <Button size='sm' onClick={() => setShow(!show)}> {show ? 'Hide' : 'Show'} </Button>
      </InputRightElement>
    </InputGroup>
  )
}